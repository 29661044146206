<template>
  <div>
    <input type="file" ref="fileInput" style="display: none" multiple @change="handleFileChange">
    <button @click="upload"  class="fileInput-btn"><img src="@/assets/img/upload.png"> Upload Photo</button>
    <div v-for="(image, index) in images" :key="index" class="imgList">
      <img :src="image.url" alt="Uploaded Image" class="img">
      <textarea type="text" v-model="image.caption" placeholder="Input Description" @input="updateCaption(index, image.caption)"></textarea>
      <div @click="deleteImage(index)" class="delete">
        <img src="@/assets/img/delete.png">
      </div>
    </div>
  </div>

  <!--提示窗-->
  <div v-if="isPopupVisible" class="popup">
    {{ popupText }}
  </div>
</template>

<script>
import { UploadImg } from "@/network/main";
export default {
  data() {
    return {
      images: [],
      isPopupVisible: false,
      popupText: '',
      emits: ['photos-updated'],
    };
  },
  methods: {
    handleFileChange() {
      //提示
      this.isPopupVisible = true
      this.popupText="Uploading....."


      const files = this.$refs.fileInput.files;
      let count = this.images.length;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (count >= 3) {
          this.isPopupVisible = true;
          this.popupText = "Only up to 3 pictures can be uploaded";
          setTimeout(() => {
            this.isPopupVisible = false;
          }, 2000);
          return;
        }
        if (file.size > 5 * 1024 * 1024) {
          this.isPopupVisible = true;
          this.popupText = "Image size cannot exceed 5M";
          setTimeout(() => {
            this.isPopupVisible = false;
          }, 2000);
          return;
        }
        const reader = new FileReader();
        reader.onload = (e) => {
          console.log(e)
          const formData = new FormData();
          formData.append('file', file);
          formData.append('path', 1);
          // 调用上传接口
          UploadImg(formData).then((res) => {
            if(res.data.code === 200){

              setTimeout(() => {
                this.isPopupVisible = false;
              }, 1000);

              // 将数据添加到 images 数组中
              this.images.push({
                url: res.data.data.url,
                caption: ''
              });
              // 将数据转换为需要的格式并传递给父组件
              const photos = this.images.map((image) => ({
                file: image.url,
                description: image.caption
              }));
              this.$emit('photos-updated', photos);

            }else if(res.data.code === 500) {
              this.popupText=res.data.message
            }

          })
          .catch((err) => {
            console.log(err);
          });
        };
        reader.readAsDataURL(file);
        count++;
      }
    },
    upload() {
      this.$refs.fileInput.click();
    },
    deleteImage(index) {
      this.images.splice(index, 1);
    },
    //更新描述
    updateCaption(index, caption) {
      this.images[index].caption = caption;

      // 将更新后的数据传递给父组件
      const photos = this.images.map((image) => ({
        file: image.url,
        description: image.caption
      }));
      this.$emit('photos-updated', photos);
    }
  }
};
</script>
<style scoped>
.fileInput-btn{
   background: var(--background);
   border: none;
   border-radius: 4px;
   height: 50px;
  line-height: 50px;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 18px;
  padding: 0 15px;
  margin: 20px 0;
}
.fileInput-btn img{
  width: 24px;
  margin-right:10px;
}
.imgList{
  display: flex;
  border-radius: 4px;
  border: 1px solid #8C8C8C;
  position: relative;
  margin-bottom: 10px;
}
.imgList .img{
  width: 88px;
  height: 88px;
  padding: 20px;
  border-right:1px solid #8C8C8C;;
}
.imgList textarea{
  width: calc(100% - 89px);
  height: 88px;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  resize: none;
}
.delete{
  width: 20px;
  height: 20px;
  position: absolute;
  top: -6px;
  right: -6px;
  cursor: pointer;
}
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0,0,0,.8);
  padding: 15px;
  border-radius: 4px;
  color: #fff;
}
</style>